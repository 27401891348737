/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on April 5, 2022 */

@font-face {
    font-family: "sf_pro_displayregular";
    src: url("../font/sfprodisplayregular-webfont.woff2") format("woff2"),
        url("../font/sfprodisplayregular-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "sf_pro_displaymedium";
    src: url("../font/sfprodisplaymedium-webfont.woff2") format("woff2"),
    url("../font/sfprodisplaymedium-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "sf_pro_displaybold";
    src: url("../font/sfprodisplaybold-webfont.woff2") format("woff2"),
    url("../font/sfprodisplaybold-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

/* @font-face {
    font-family: "sf_pro_displayblack_italic";
    src: url("../font/sfprodisplayblackitalic-webfont.woff2") format("woff2"),
        url("../font/sfprodisplayblackitalic-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
} */


/* @font-face {
    font-family: "sf_pro_displayheavy_italic";
    src: url("../font/sfprodisplayheavyitalic-webfont.woff2") format("woff2"),
        url("../font/sfprodisplayheavyitalic-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
} */

/* @font-face {
    font-family: "sf_pro_displaylight_italic";
    src: url("../font/sfprodisplaylightitalic-webfont.woff2") format("woff2"),
        url("../font/sfprodisplaylightitalic-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
} */

/* @font-face {
    font-family: "sf_pro_displaysemibold_italic";
    src: url("../font/sfprodisplaysemibolditalic-webfont.woff2") format("woff2"),
        url("../font/sfprodisplaysemibolditalic-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "sf_pro_displaythin_italic";
    src: url("../font/sfprodisplaythinitalic-webfont.woff2") format("woff2"),
        url("../font/sfprodisplaythinitalic-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "sf_pro_displayultraLtIt";
    src: url("../font/sfprodisplayultralightitalic-webfont.woff2") format("woff2"),
        url("../font/sfprodisplayultralightitalic-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
} */

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: inherit;
    line-height: 1.5;
    margin: 0px;
}

h1 {
    font-size: 22px;
}
@media screen and (min-width: 1600px) {
    h6 {
        font-size: 24px;
    }
}
@media screen and (max-width: 992px) {
    h1 {
        font-size: 20px;
    }
}
@media screen and (max-width: 576px) {
    h1 {
        font-size: 18px;
    }
}

h2 {
    font-size: 20px;
}
@media screen and (min-width: 1600px) {
    h6 {
        font-size: 22px;
    }
}
@media screen and (max-width: 992px) {
    h2 {
        font-size: 18px;
    }
}
@media screen and (max-width: 576px) {
    h2 {
        font-size: 16px;
    }
}

h3 {
    font-size: 18px;
}
@media screen and (min-width: 1600px) {
    h6 {
        font-size: 20px;
    }
}
@media screen and (max-width: 992px) {
    h3 {
        font-size: 16px;
    }
}
@media screen and (max-width: 576px) {
    h3 {
        font-size: 14px;
    }
}

h4, .MuiBreadcrumbs-li {
    font-size: 16px;
}
@media screen and (min-width: 1600px) {
    h4, .MuiBreadcrumbs-li {
        font-size: 18px;
    }
}
@media screen and (max-width: 992px) {
    h4, .MuiBreadcrumbs-li {
        font-size: 14px;
    }
}
@media screen and (max-width: 576px) {
    h4, .MuiBreadcrumbs-li {
        font-size: 13px;
    }
}

h5 {
    font-size: 14px;
}
@media screen and (min-width: 1600px) {
    h5{
        font-size: 18px;
    }
}
@media screen and (max-width: 991px) {
    h5 {
        font-size: 12px;
    }
}
@media screen and (max-width: 576px) {
    h5 {
        font-size: 11px;
    }
}

h6 {
    font-size: 12px;
}
@media screen and (min-width: 1600px) {
    h6 {
        font-size: 14px;
    }
}
@media screen and (max-width: 992px) {
    h6 {
        font-size: 10px;
    }
}
@media screen and (max-width: 576px) {
    h6 {
        font-size: 9px;
    }
}

p {
    font-size: 16px;
    margin-top: 0;
    line-height: 1.5;
}
@media screen and (min-width: 1600px) {
    h6 {
        font-size: 18px;
    }
}
@media screen and (max-width: 992px) {
    p {
        font-size: 14px;
    }
}
@media screen and (max-width: 576px) {
    p {
        font-size: 12px;
    }
}
