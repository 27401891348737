header {
  background-color: var(--primary) !important;
  height: 80px;
}
header .logo-cover {
  width: auto;
  height: 55px;
}
header .logo-cover img {
  height: 100%;
  width: auto;
}
header .logo-cover .logo-for-mobile {
  display: none;
}
header .bell-icon{
  width: 25px;
}
header .for-web.d-flex{
  display: flex;
}
header a.white {
  padding: 15px 15px;
  font-size: 20px;
}
header a {
  border-bottom: 4px solid transparent;
}
header a.active {
  border-color: white;
}

@media screen and (max-width: 1100px) {
  header .logo-cover .logo-for-desktop {
    display: none;
  }
  header .logo-cover .logo-for-mobile {
    display: block;
  }
}
header .for-mobile {
  display: none;
}
header .MuiToolbar-root {
  padding: 3px 7%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}
header .profile-icon {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  border: 2px solid var(--white);
  margin-left: 10px;
}
header .MuiToolbar-root .MuiButton-root {
  font-size: 18px;
  padding: 19.5px 16px;
  text-transform: capitalize;
  border-radius: 0px;
}
header .MuiToolbar-root .MuiButton-root.logout-btn {
  padding: 10px 25px;
  min-width: auto;
  position: relative;
  margin-right: -25px;
}
header .MuiToolbar-root .MuiButton-root.logout-btn::before {
  content: "";
  position: absolute;
  top: 5px;
  bottom: 5px;
  left: -2px;
  margin-left: 2px;
  width: 2px;
  background-color: #929fc4;
}
header .MuiToolbar-root .MuiButton-root {
  border-bottom: 3px solid transparent;
  margin-right: 10px;
}
header .MuiToolbar-root .MuiButton-root.account-btn {
  padding-right: 25px;
  margin-right: 0px !important;
}
header .MuiToolbar-root .MuiButton-root.active {
  border-bottom: 3px solid var(--white);
}
header .menu-btn {
  margin: 0px 0px 0px -15px !important;
  padding: 15px !important;
  color: var(--white);
}
header .MuiBadge-badge{
  color: var(--primary);
  background-color: var(--white);
  top: 5px;
}
@media screen and (min-width: 1600px) {
  header {
    height: 107px;
  }
  header a.white {
    padding: 15px 25px;
    font-size: 22px;

  }
  header .MuiToolbar-root .MuiButton-root {
    font-size: 23px;
  }
  header .MuiToolbar-root .MuiButton-root:not(:last-child) {
    margin-right: 20px;
  }
  header .MuiToolbar-root {
    min-height: 107px !important;
  }
}
@media screen and (max-width: 991px) {
  header .for-mobile {
    display: block;
  }
  header .for-web {
    display: none;
  }
  header .for-web.d-flex {
    display: none;
  }
  header a.white {
    padding: 10px 15px;
  }
  header .bell-icon{
    width: 23px;
  }
}
@media screen and (max-width: 576px) {
  header {
    height: 56px;
  }
  header .logo-cover {
    width: 35px;
    height: 35px;
  }
  header .bell-icon{
    width: 20px;
  }
  header a.active {
    border: none;
  }
  header .MuiBadge-badge{
    right: -5px;
  }
  header .MuiToolbar-root .MuiButton-root {
    font-size: 14px !important;
  }
  header .MuiToolbar-root .MuiButton-root:not(:last-child) {
    margin-right: 10px;
  }
  header .MuiToolbar-root .MuiButton-root {
    padding: 10px;
  }
  header .MuiToolbar-root .MuiButton-root.account-btn {
    padding-right: 15px;
  }
  header .MuiToolbar-root .account-text {
    display: none;
  }
  header .MuiToolbar-root .MuiButton-root.logout-btn::before {
    top: 8px;
    bottom: 7px;
  }
  header .MuiToolbar-root .MuiButton-root.logout-btn {
    padding: 10px 20px 10px 18px;
    margin-right: -20px;
  }
}
