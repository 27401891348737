.popup-ui{
    padding: 30px;
    text-align: center;
    width: 550px;
    max-width: 100%;
}

.popup-ui .signup-pass .MuiFormHelperText-root.Mui-error {
    position: inherit;
    top: auto;
    text-align: right;
}

@media screen and (max-width: 450px){
    .popup-ui{
        padding: 20px;
    }
}
.popup-ui .icon-cover{
    margin: 0px auto 30px;
}
.popup-ui h3{
    width: 360px;
    max-width: 100%;
    margin: auto;
}