.page-comp-filter .comp-filter-content {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}
.page-comp-filter .comp-filter-content .MuiTabs-vertical {
  width: 300px;
  text-align: left;
  border-right: none;
}
.page-comp-filter .comp-filter-content .MuiTabs-vertical .MuiTab-root {
  font-size: 18px;
  background-color: #f6fbfe;
  padding: 25px 40px;
  border-bottom: 1px solid #bee8fb;
  align-items: flex-start;
  position: relative;
}
.page-comp-filter .comp-filter-content .MuiTabs-vertical .MuiTab-root.Mui-selected {
  background-color: white;
  color: var(--secondary);
  font-weight: bold;
}
.page-comp-filter .comp-filter-content .MuiTabs-vertical .MuiTab-root.Mui-selected::before {
  content: "";
  position: absolute;
  top: 15%;
  height: 70%;
  background-color: var(--secondary);
  width: 2px;
  left: 0px;
}
.page-comp-filter .comp-filter-content .MuiTabs-vertical .MuiTabs-indicator {
  display: none;
}
.page-comp-filter .comp-filter-content .panel-cover {
  flex: 1;
}
.page-comp-filter .btn-cover {
  min-width: 250px;
  max-width: 100%;
}
.page-comp-filter .slider-cover {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 120px;
}
.page-comp-filter .search-cover .search-input {
  width: 100%;
  position: relative;
}
.page-comp-filter .search-cover .search-input .search {
  padding: 15px 50px;
  border-radius: 40px;
  width: 100%;
  border: 1px solid var(--primary);
  font-size: 18px;
  color: #666666;
}
.page-comp-filter .search-cover .search-input .search-icon {
  position: absolute;
  left: 20px;
  top: 15px;
  width: 20px;
}
.page-comp-filter .search-cover .search-input .close-icon {
  position: absolute;
  right: 20px;
  top: 15px;
  width: 20px;
}
@media screen and (min-width: 1600px) {
  .page-comp-filter .search-cover .search {
    padding: 18px 50px !important;
  }
  .page-comp-filter .search-cover .search-input .search-icon {
    top: 18px;
    width: 23px;
  }
  .page-comp-filter .search-cover .search-input .close-icon {
    top: 18px;
    width: 23px;
  }
}
.page-comp-filter .slider-cover .slider-value {
  width: 140px;
  padding: 15px 42px;
  border-radius: 10px;
  background-color: var(--input-border);
  color: white;
  font-weight: bold;
}

.page-comp-filter .slider-cover .MuiSlider-colorPrimary {
  margin: 0px 40px;
  flex: 1;
  margin-top: 10px;
  height: 20px;
  border: 5px solid #bfe8fb;
  border-radius: 50px;
  padding: 0px;
}
.page-comp-filter .slider-cover .MuiSlider-colorPrimary .MuiSlider-rail {
  background: #bfe8fb;
  opacity: 1;
}
.page-comp-filter .slider-cover .MuiSlider-colorPrimary .MuiSlider-track {
  background: #254089;
}

.page-comp-filter .slider-cover .MuiSlider-colorPrimary .MuiSlider-thumbColorPrimary {
  background: #254089;
  width: 41px;
  height: 41px;
  border: 5px solid #e7f6ff;
}
.page-comp-filter .infinite-scroll-component {
  overflow-x: hidden !important;
  padding: 30px 11px 0px 0px;
}

.page-comp-filter .single-broker {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-right: 25px;
  padding-bottom: 20px;
  border-bottom: 1px solid #bcbec0;
  position: relative;
  flex-wrap: wrap;
}
.page-comp-filter .single-broker .broker-img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 20px;
}
.page-comp-filter .single-broker .broker-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.page-comp-filter .infinite-scroller1 {
  overflow-y: scroll;
  max-height: 500px;
  width: calc(100% + 32px);
  padding-right: 10px;
  overflow-x: hidden;
}
.page-comp-filter .single-broker .radio-btn {
  position: absolute;
  right: 0px;
  top: 12px;
  width: 20px;
  height: 20px;
}

.page-comp-filter .MuiTab-root {
  position: relative;
}
.page-comp-filter .MuiTab-root.applied::after {
  content: "";
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  background-color: #254089;
  border-radius: 50%;
}
.page-comp-filter .radio-cover {
  flex-direction: row;
}
.page-comp-filter .radio-cover .MuiFormControlLabel-root {
  min-width: 30%;
}

.page-comp-filter .tenants-cover {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
  overflow: auto;
  max-width: 100%;
}
.page-comp-filter .add-tenant-cover {
  border: 2px solid var(--input-border);
  padding: 25px;
  border-radius: 8px;
  background-color: var(--white);
  display: flex;
  align-items: center;
  text-align: center;
  margin: 10px 30px;
  justify-content: center;
  overflow: hidden;
  min-height: 260px;
  width: 266px;
}
.page-comp-filter .tenant-added {
  width: 100%;
}
.page-comp-filter .add-tenant-cover .tenant-img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  margin: 30px auto;
}
.page-comp-filter .add-tenant-cover .tenant-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 1200px) {
  .page-comp-filter .comp-filter-content .panel-cover > div {
    padding: 30px !important;
  }
}

@media screen and (max-width: 991px) {
  .page-comp-filter .comp-filter-content {
    flex-wrap: wrap;
  }
  .page-comp-filter .comp-filter-content .MuiTabs-vertical {
    width: 100%;
    background-color: #f6fbfe;
    border-bottom: 1px solid #caeafb;
  }
  .page-comp-filter .comp-filter-content .MuiTabs-vertical .MuiTabs-flexContainer {
    flex-direction: row;
    overflow: auto;
  }
  .page-comp-filter .comp-filter-content .MuiTabs-vertical .MuiTab-root.Mui-selected::before {
    top: auto;
    bottom: 0px;
    width: 60px;
    height: 2px;
    left: 50%;
    transform: translateX(-50%);
  }
  .page-comp-filter .comp-filter-content .MuiTabs-vertical .MuiTab-root {
    font-size: 14px;
    border-bottom: none;
    padding: 15px 20px;
  }
}

@media screen and (max-width: 768px) {
  .page-comp-filter .comp-filter-content .panel-cover > div {
    padding: 20px !important;
  }
  .page-comp-filter .slider-cover {
    flex-wrap: wrap;
    margin-top: 50px;
  }
  .page-comp-filter .slider-cover .MuiSlider-colorPrimary {
    width: 100%;
    order: 1;
    margin: 30px 0px 60px;
    flex: auto;
  }
}

@media screen and (max-width: 576px) {
  .page-comp-filter .search-cover {
    padding-right: 0px;
    border-right: none;
  }
  .page-comp-filter .search-cover .search {
    font-size: 12px !important;
    padding: 6px 25px !important;
  }
  .page-comp-filter .search-cover .search-input .search-icon {
    top: 9px;
    width: 10px;
    left: 10px;
  }
  .page-comp-filter .search-cover .search-input .close-icon {
    top: 9px;
    width: 10px;
    right: 10px;
  }
  .page-comp-filter .slider-cover .slider-value {
    width: 90px;
    padding: 10px 15px;
    border-radius: 10px;
    font-size: 14px;
  }
  .page-comp-filter .slider-cover .MuiSlider-colorPrimary {
    height: 12px;
    border: 2px solid #bfe8fb;
  }
  .page-comp-filter .slider-cover .MuiSlider-colorPrimary .MuiSlider-thumbColorPrimary {
    width: 24px;
    height: 24px;
    border: 2px solid #e7f6ff;
  }
}

.page-comp-filter .single-broker .MuiFormControlLabel-root {
  flex: 1;
  height: 70px;
  margin-left: 10px;
  position: inherit;
  margin-right: -20px;
}