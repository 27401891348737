.page-add-comps .add-comp-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #d3d3d3;
}
.page-add-comps .radio-tabs {
  flex-direction: row !important;
  width: max-content;
}
.page-add-comps .lease-sale-tab {
  margin: 16px 0px 16px 30px;
  padding-right: 30px;
  border-right: 1px solid #d3d3d3;
}
.page-add-comps .radio-tabs label {
  width: 150px;
  padding: 16px 20px;
  display: inline-block;
  background: #e7f6ff;
  margin: 0px;
  color: var(--primary);
  text-align: center;
}
.page-add-comps .radio-tabs label:first-child {
  border-radius: 40px 0px 0px 40px;
}
.page-add-comps .radio-tabs label:last-child {
  border-radius: 0px 40px 40px 0px;
}
.page-add-comps .radio-tabs label.active {
  background-color: var(--primary);
  color: var(--white);
}
.page-add-comps .radio-tabs.small label {
  padding: 6px 20px;
  width: max-content;
}
.page-add-comps .radio-tabs label .MuiTypography-root {
  font-weight: bold !important;
  font-size: 18px;
}
.page-add-comps .radio-tabs.small label .MuiTypography-root {
  font-weight: 500 !important;
  font-size: 16px;
}
.page-add-comps .radio-tabs label .MuiRadio-root {
  width: 0px;
  padding: 0px;
}
.page-add-comps .radio-tabs label .MuiRadio-root span {
  display: none;
}

.page-add-comps .sale-price-cover {
  min-width: 90px;
  padding: 0px 10px;
  height: 40px;
  border-radius: 5px;
  background-color: var(--input-border);
  color: white;
  font-size: 18px;
  font-weight: bold;
}

.page-add-comps .add-comp-header .steps-tab {
  padding: 0px 20px;
  flex: 1;
}
.page-add-comps .add-comp-header .steps-tab .MuiTabs-flexContainer {
  justify-content: space-evenly;
  overflow: auto;
}
.page-add-comps .add-comp-header .steps-tab .MuiTabs-flexContainer .MuiButtonBase-root {
  font-size: 16px;
  padding: 36px 16px;
  text-transform: capitalize;
}
@media screen and (min-width: 1600px) {
  .page-add-comps .add-comp-header .steps-tab .MuiTabs-flexContainer .MuiButtonBase-root {
    font-size: 23px;
  }
  .page-add-comps .radio-tabs label .MuiTypography-root {
    font-size: 23px;
  }
  .page-add-comps .add-comp-header .toggle-cover .MuiTypography-root {
    font-size: 23px;
  }
}
.page-add-comps .add-comp-header .steps-tab .MuiTabs-flexContainer .MuiButtonBase-root.Mui-selected {
  font-weight: 600;
  color: var(--secondary);
}
.page-add-comps .add-comp-header .steps-tab .MuiTabs-indicator {
  background-color: var(--secondary);
}
.page-add-comps .add-comp-header .toggle-cover {
  border-left: 1px solid #d3d3d3;
  padding: 11px 30px;
}
.page-add-comps .add-comp-header .toggle-cover .MuiTypography-root {
  color: var(--primary);
  font-weight: 600;
}
.page-add-comps .add-comp-body {
  padding: 20px 30px;
}
.page-add-comps .add-comp-body .btn-cover {
  flex-wrap: wrap;
  margin: 30px -15px 0px;
}
.page-add-comps .add-comp-body .btn-cover .btn {
  width: 200px;
  max-width: 100%;
  margin: 15px;
}
.page-add-comps .search-input {
  width: 100%;
  position: relative;
}
.page-add-comps .search {
  font-size: 16px;
  padding: 16.5px 50px 16.5px 16.5px !important;
  border-radius: 4px;
  width: 100%;
  line-height: 1.2;
  border: 2px solid var(--input-border);
  font-weight: 700;
}
.page-add-comps .search-input .target-icon {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  width: 22px;
}
@media screen and (min-width: 1600px) {
  .page-add-comps .search {
    font-size: 21px;
    padding: 24px 50px 24px 24px !important;
  }
  .page-add-comps .search-input .target-icon {
    width: 24px;
  }
}

.page-add-comps .tenants-cover {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  overflow: auto;
  max-width: 100%;
}
.page-add-comps .add-tenant-cover {
  border: 2px solid var(--input-border);
  padding: 25px;
  border-radius: 8px;
  background-color: var(--white);
  display: flex;
  align-items: center;
  text-align: center;
  margin: 10px 30px;
  justify-content: center;
  overflow: hidden;
  min-height: 260px;
  width: 266px;
}
.page-add-comps .tenant-added {
  width: 100%;
}
.page-add-comps .add-tenant-cover .tenant-img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  margin: 30px auto;
}
.page-add-comps .add-tenant-cover .tenant-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.page-add-comps .add-property-image {
  background-color: #f6fbfe;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  height: 280px;
}
.page-add-comps .add-property-image .default-look {
  text-align: center;
}
.page-add-comps .add-property-image .uploaded-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.page-add-comps .MuiInputAdornment-root p {
  font-weight: bold;
  color: black;
  font-size: 17px;
  margin-top: 1px;
}
.page-add-comps .cap-rate-perc .MuiInputAdornment-positionStart {
  display: none;
}
.page-add-comps .baserent-field-cover {
  position: relative;
}
.page-add-comps .baserent-field-cover .MuiOutlinedInput-input {
  padding-right: 5px;
}
.page-add-comps .baserent-field-cover .MuiInputAdornment-positionEnd {
  margin-right: 35px;
}
.page-add-comps .baserent-field-cover .info-icon {
  position: absolute;
  top: 50%;
  right: 18px;
  transform: translateY(-50%);
  cursor: pointer;
}
@media screen and (min-width: 1600px) {
  .page-add-comps .cap-rate-perc .MuiInputAdornment-positionStart {
    display: none;
  }
  .page-add-comps .MuiInputAdornment-root p {
    font-size: 21px;
    margin-left: 11px;
    margin-top: 0px;
  }
  .page-add-comps .cap-rate-perc .MuiOutlinedInput-root {
    padding-left: 0px !important;
  }
}
@media screen and (max-width: 1350px) {
  .page-add-comps .base-rent .MuiFormHelperText-root.Mui-error {
    position: inherit;
    top: auto;
    text-align: right;
  }
  .page-add-comps .baserent-field-cover .info-icon {
    top: 33%;
  }
}
@media screen and (max-width: 1260px) {
  .page-add-comps .add-tenant-cover {
    margin: 10px 20px;
    padding: 15px 20px;
    width: calc(100% / 3 - 40px);
  }
  .page-add-comps .add-tenant-cover .tenant-img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin: 10px auto;
    overflow: hidden;
  }
  .page-add-comps .add-tenant-cover .tenant-img img {
    width: 100%;
    height: 100%;
  }
}
@media screen and (max-width: 1200px) {
  .page-add-comps .add-comp-header .toggle-cover {
    padding: 4px 20px 4px 5px;
  }
  .page-add-comps .radio-tabs label {
    width: 100px;
    padding: 10px 15px;
  }
  .page-add-comps .add-comp-header .steps-tab .MuiTabs-flexContainer .MuiButtonBase-root {
    padding: 30px 10px;
  }
}
@media screen and (max-width: 991px) {
  .page-add-comps .add-comp-header {
    padding: 25px 25px 0px 25px;
    flex-wrap: wrap;
  }
  .page-add-comps .lease-sale-tab {
    width: 55%;
    order: 0;
    margin: 0px;
    border: none;
  }
  .page-add-comps .add-comp-header .steps-tab {
    width: 100%;
    order: 2;
    padding: 0px;
  }
  .page-add-comps .add-comp-header .toggle-cover {
    order: 0;
    width: 45%;
    text-align: right;
    padding: 0px;
    border: none;
  }
  .page-add-comps .add-tenant-cover {
    margin: 5px 10px;
    padding: 10px 15px;
    width: 170px;
    min-height: 160px;
  }
  .page-add-comps .add-tenant-cover .default-look img {
    width: 60px;
    height: 60px;
  }
  .page-add-comps .add-tenant-cover h3,
  .page-add-comps .add-tenant-cover a {
    font-size: 14px;
  }
  .page-add-comps .add-tenant-cover h2 {
    font-size: 15px;
  }
  .page-add-comps .add-tenant-cover p {
    font-size: 12px;
  }
  .page-add-comps .add-tenant-cover .tenant-img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin: 5px auto;
    overflow: hidden;
  }
  .page-add-comps .add-tenant-cover img.pointer {
    width: 12px;
  }
}
@media screen and (max-width: 768px) {
  .page-add-comps .add-comp-body {
    padding: 15px 20px;
  }
}
@media screen and (max-width: 576px) {
  .page-add-comps .add-tenant-cover {
    min-width: 165px;
    width: calc(100% / 3);
  }
  .page-add-comps .add-comp-header {
    padding: 20px 15px 0px 15px;
  }
  .page-add-comps .radio-tabs label {
    width: 80px;
    padding: 7px 15px;
  }
  .page-add-comps .radio-tabs label .MuiTypography-root {
    font-size: 14px;
  }
  .page-add-comps .sale-price-cover {
    width: 40px;
    height: 23px;
    font-size: 12px;
  }
  .page-add-comps .add-comp-header .steps-tab .MuiTabs-flexContainer .MuiButtonBase-root {
    padding: 20px 12px 15px;
    font-size: 14px;
  }
  .page-add-comps .radio-tabs.small label {
    padding: 2px 6px;
    line-height: 12px;
  }
  .page-add-comps .radio-tabs.small label .MuiTypography-root {
    font-size: 10px;
  }
  .page-add-comps .MuiInputAdornment-root p {
    font-size: 14px;
  }
  .page-add-comps .add-comp-body .btn-cover .btn {
    width: calc(50% - 40px);
  }
}
@media screen and (max-width: 450px) {
  .page-add-comps .add-comp-header {
    padding: 10px 11px 0;
  }
  .page-add-comps .lease-sale-tab {
    padding-right: 0px;
    width: 42%;
  }
  .page-add-comps .add-comp-header .toggle-cover {
    width: 55%;
  }
  .page-add-comps .radio-tabs {
    margin: 0px;
  }
  .page-add-comps .radio-tabs label {
    width: 55px;
    padding: 4px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .page-add-comps .radio-tabs label .MuiTypography-root {
    font-size: 12px;
  }
  .page-add-comps .baserent-field-cover .info-icon {
    width: 18px;
    right: 14px;
  }
}

@media screen and (max-width: 400px) {
  .page-add-comps .add-comp-header .steps-tab .MuiTabs-flexContainer .MuiButtonBase-root {
    padding: 12px 8px 8px;
    font-size: 13px;
    min-width: auto;
  }
}

.zoning-helper {
  text-align: right;
  color: #828282;
  font-size: 14px;
  display: block;
  line-height: 2;
}
.page-add-comps .add-comp-header .toggle-cover .disable-text .MuiTypography-root {
  color: #cccccc;
}
.page-add-comps .MuiSwitch-switchBase .MuiSwitch-thumb {
  background-color: #cccccc;
}
.page-add-comps .MuiSwitch-switchBase + .MuiSwitch-track {
  background-color: #cccccc;
}
.page-add-comps .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb {
  background-color: var(--primary);
}
.page-add-comps .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
  background-color: var(--primary) !important;
}
