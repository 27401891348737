.page-contact-us .contact-us-content{
    padding: 30px 40px;
    border-radius: 10px;
}
.page-contact-us .btn{
    width: 400px;
    max-width: 100%;
}

@media screen and (max-width: 767px) {
    .page-contact-us .contact-us-content{
        padding: 15px 20px;
    }
}