.page-onboarding {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;
}
.page-onboarding .left-portion {
    width: 50%;
    padding-top: 12px;
}
.page-onboarding .left-portion .alice-carousel__wrapper .alice-carousel__stage-item {
    text-align: center;
}
.page-onboarding .left-portion .alice-carousel__wrapper .alice-carousel__stage-item img {
    width: auto;
    max-width: 100%;
    min-height: 300px;
    height: auto;
    max-height: calc(100vh - 145px);
}
.page-onboarding .left-portion .alice-carousel__wrapper .alice-carousel__dots {
    margin-top: 0px;
}
.page-onboarding .left-portion .alice-carousel__dots {
    display: flex;
    align-items: center;
    justify-content: center;
}
.page-onboarding .left-portion .alice-carousel__dots .__active {
    width: 14px;
    height: 14px;
    background-color: var(--input-border);
}
.page-onboarding .right-portion {
    width: 50%;
    padding: 50px;
    overflow-y: auto;
    max-height: calc(100vh - 80px);
    position: sticky;
    top: 80px;
}

.page-onboarding .right-portion .onboarding-content {
    width: 80%;
}
.page-onboarding .right-portion .onboarding-content .back-icon {
    color: var(--primary);
    background-color: #e7f6ff;
    margin-bottom: 20px;
    padding: 5px;
    border-radius: 5px;
    width: 34px;
    height: 34px;
    cursor: pointer;
}
.page-onboarding .right-portion .onboarding-content h1 {
    font-size: 34px;
    color: var(--primary);
}
.page-onboarding .right-portion .onboarding-content h2 {
    font-size: 20px;
}
.page-onboarding .right-portion .onboarding-content .MuiCheckbox-root {
    margin-right: 5px;
}
.page-onboarding .right-portion .onboarding-content .otp-cover {
    display: flex;
    align-items: center;
    margin-top: 20px;
    justify-content: space-between;
}
.page-onboarding .right-portion .onboarding-content .otp-cover input {
    width: 70px !important;
    height: 70px !important;
    font-size: 30px;
    margin-right: 0px !important;
    border-radius: 10px;
    border: 2px solid var(--input-border);
}
@media screen and (min-width: 1600px){
    .page-onboarding .right-portion .onboarding-content .otp-cover input {
        width: 100px !important;
        height: 100px !important;
    }
}
.page-onboarding .right-portion .onboarding-content .otp-cover input:not(:last-child) {
    margin-right: 40px !important;
}
.page-onboarding .right-portion .onboarding-content .timer span {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 20px;
    font-family: 'sf_pro_displayregular';
}
.page-onboarding .right-portion .onboarding-content .timer span .MuiSvgIcon-root {
    margin-right: 5px;
}
.page-onboarding .right-portion .onboarding-content .timer button {
    background: none !important;
    color: var(--primary) !important;
    text-decoration: underline;
    font-family: 'sf_pro_displayregular';
}
.page-onboarding .right-portion .onboarding-content .timer button span {
    font-size: 16px !important;
    cursor: pointer;
    font-weight: 600;
}

@media screen and (min-width: 1600px) {
    .page-onboarding .left-portion .alice-carousel__wrapper .alice-carousel__stage-item img{
        height: calc(100vh - 180px);
    }
    .page-onboarding .right-portion {
        max-height: calc(100vh - 107px);
        position: sticky;
        top: 107px;
    }
    .page-onboarding .right-portion .onboarding-content h1 {
        font-size: 44px;
    }
    .page-onboarding .right-portion .onboarding-content h2 {
        font-size: 26px;
    }
    .page-onboarding .right-portion .onboarding-content h2.subtext.center {
        font-size: 23px;
    }
    .page-onboarding .right-portion .onboarding-content h4,
    .page-onboarding .right-portion .onboarding-content span,
    .page-onboarding .right-portion .onboarding-content a {
        font-size: 21px;
    }
    .page-onboarding .right-portion .onboarding-content p.color-red {
        font-size: 22px;
    }
    .page-onboarding .right-portion .onboarding-content .accept-check {
        margin: 20px 0px;
    }
}

.page-onboarding .signup-pass .MuiFormHelperText-root.Mui-error {
    position: inherit;
    top: auto;
    text-align: right;
}


@media screen and (max-width: 992px){
    .page-onboarding .right-portion .onboarding-content {
        width: 100%;
    }
}

@media screen and (max-width: 820px){
    .page-onboarding .left-portion {
        width: 100%;
        padding-top: 20px;
        padding-bottom: 40px;
    }
    .page-onboarding .right-portion{
        width: 100%;
        max-height: auto;
        padding: 20px 50px 50px;
    }
    .page-onboarding .right-portion .onboarding-content h1 {
        font-size: 28px;
    }
    .page-onboarding .right-portion .onboarding-content h2 {
        font-size: 18px;
    }
}
@media screen and (max-width: 576px){
    .page-onboarding .left-portion{
        display: none;
    }
    .page-onboarding .right-portion{
        padding: 40px 30px;
        max-height: calc(100vh - 48px);
        top: 48px;
    }
    .page-onboarding .right-portion .onboarding-content h1 {
        font-size: 22px;
    }
    .page-onboarding .right-portion .onboarding-content h2 {
        font-size: 14px;
    }
    .page-onboarding .right-portion .onboarding-content .otp-cover input {
        width: 50px !important;
        height: 50px !important;
        font-size: 22px;
    }
    .page-onboarding .right-portion .onboarding-content .otp-cover input:not(:last-child) {
        margin-right: 20px !important;
    }
}