.static-page {
    margin: 0px;
    padding: 50px 40px 0px 40px;
    min-height: 100vh;
    background-color: #f6f6f6;
}
.static-page .static-page-content {
    padding: 40px 40px 60px 40px;
    background-color: white;
    border-radius: 10px 10px 0px 0px;
}
.static-page .static-page-content h1 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
}
.static-page-content h2, .static-page-content h3, .static-page-content li {
    line-height: 1.7 !important;
}
.static-page-content ol{
    padding-left: 30px;
}
.static-page-content ol li{
    list-style-type: auto;
}
.static-page-content ul{
    padding-left: 25px;
}
.static-page-content ul li{
    list-style-type: disc;
}
.static-page-content a{
    color: #fb7122;
    border-color: #fb7122;
}
@media screen and (max-width: 767px){
    .static-page {
        margin: 0px;
        padding: 30px 25px 0px 25px;
        background-color: #f6f6f6;
    }
    .static-page .static-page-content {
        padding: 25px 25px 35px 25px;
    }
}
