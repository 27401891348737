@import "./var.css";

* {
  outline: none;
  word-break: break-word;
  list-style: none;
  margin: 0;
  padding: 0;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

.space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-start {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.justify-start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.justify-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.align-flex-end {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.flex-wrap {
  flex-wrap: wrap;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize !important;
}

.underline {
  padding-bottom: 0px;
  border-bottom: 1px solid var(--primary);
}
.underline.color-secondary {
  border-bottom: 1px solid var(--secondary);
}
.no-underline {
  text-decoration: none !important;
  border-bottom: none;
}
.nowrap {
  white-space: nowrap;
}

.bold {
  font-weight: 700;
}
.semi-bold {
  font-weight: 600;
}
.thin {
  font-weight: 400;
}
.regular {
  font-weight: 500;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.left {
  text-align: left !important;
}

.pointer {
  cursor: pointer;
}

.color-primary {
  color: var(--primary);
}
.color-secondary {
  color: var(--secondary) !important;
}
.subtext {
  color: var(--grey);
}
.camelcase {
  text-transform: capitalize;
}
.color-green {
  color: var(--green);
}

.color-red {
  color: var(--red);
}

.color-orange {
  color: var(--orange);
}
.white {
  color: white !important;
}

a {
  color: var(--primary);
  font-weight: 600;
  cursor: pointer;
  text-decoration: none;
  border-bottom: 1px solid var(--primary);
}
a.color-secondary {
  border-bottom-color: var(--secondary);
}

.border {
  border: 1px solid var(--border-color);
}

.box-shadow {
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.2);
}

.mb-5 {
  margin-bottom: 5px !important;
}
.mb-5.subtext {
  margin-bottom: 7px !important;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-30 {
  margin-bottom: 30px;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.ml-5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-30 {
  margin-left: 30px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-20 {
  margin-right: 20px !important;
}

.mr-30 {
  margin-right: 30px;
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}
.bg-white {
  background-color: white;
}
.bg-white-with-padding {
  background-color: white;
  padding: 30px;
}
.bg-light-blue {
  background-color: #f6fbfe;
  border-radius: 8px;
  padding: 20px;
}

/* @media screen and (max-width: 1600px){   
    .MuiBreadcrumbs-li{
        
    }
} */
.read-only-select .MuiSelect-select {
  color: #8b8e8f;
}

.MuiBackdrop-root {
  pointer-events: none;
}
