.page-faq .faq-content {
    padding: 40px;
    border-radius: 10px;
}
.page-faq .faq-content .MuiAccordionSummary-root{
    padding: 0px 20px;
}
.page-faq .search-input {
    width: 100%;
    position: relative;
}
.page-faq .search {
    padding: 15px 50px;
    border-radius: 40px;
    width: 100%;
    border: 1px solid var(--primary);
    font-size: 18px;
    color: #666666;
}
.page-faq .search-input .search-icon {
    position: absolute;
    left: 20px;
    top: 15px;
    width: 20px;
}
.page-faq .search-input .close-icon {
    position: absolute;
    right: 20px;
    top: 15px;
    width: 20px;
}
@media screen and (min-width: 1600px) {
    .page-faq .search {
        padding: 18px 50px !important;
    }
    .page-faq .search-input .search-icon {
        top: 18px;
        width: 23px;
    }
    .page-faq .search-input .close-icon {
        top: 18px;
        width: 23px;
    }
}
@media screen and (max-width: 767px) {
    .page-faq .faq-content {
        padding: 15px 20px;
    }
}

@media screen and (max-width: 576px) {
    .page-dashboard .top-portion .space-between {
        padding-left: 10px;
        padding-right: 10px;
    }
    .page-faq .search-input {
        padding-right: 0px;
        margin-right: 20px;
        border-right: none;
    }
    .page-faq .search {
        font-size: 12px;
        padding: 6px 25px !important;
    }
    .page-faq .search-input .search-icon {
        top: 9px;
        width: 10px;
        left: 10px;
    }
    .page-faq .search-input .close-icon {
        top: 9px;
        width: 10px;
        right: 10px;
    }
}
