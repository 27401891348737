.popup-msg {
  padding: 30px;
  text-align: center;
  width: 580px;
  max-width: 100%;
}
@media screen and (max-width: 450px) {
  .popup-msg {
    padding: 20px;
  }
}
.popup-msg .close-icon {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  font-size: 24px;
}
.popup-msg .icon-cover {
  margin: 0px auto 30px;
}
.popup-msg h3 {
  max-width: 100%;
  margin: auto;
}
.popup-msg h1.color-primary{
  display: inline-block;
}

.popup-msg h1.color-primary:hover{
  text-decoration: underline;
}

.popup-msg.alert-popup {
  width: 380px;
}
.popup-msg.alert-popup h3 {
  margin-top: 20px;
}
