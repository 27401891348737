.page-broker-detail .container {
    background-color: white;
    padding: 30px;
}

.page-broker-detail h1 {
    font-size: 34px;
}
.page-broker-detail .btn-secondary {
    width: 450px;
    max-width: 100%;
}
.add-lic-cover.disabled {
    opacity: .5;
    cursor: not-allowed;
}

@media screen and (max-width: 820px){
    .page-broker-detail h1 {
        font-size: 28px;
    }
}
@media screen and (max-width: 576px){
    .page-broker-detail .container {
        padding: 20px;
    }
    .page-broker-detail h1 {
        font-size: 22px;
    }
}