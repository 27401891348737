.MuiFormControl-root,
.MuiTextField-root,
.MuiInput-root,
.MuiInputBase-root {
    width: 100%;
    font-weight: bold;
}
.MuiTextField-root {
    position: relative;
}
input::placeholder,
.MuiSelect-select::placeholder {
    font-weight: 100 !important;
    color: #a6a6a6;
}
input,
.MuiSelect-select {
    font-weight: bold !important;
}
input:read-only {
    opacity: 0.5;
}
.readonly fieldset {
    border-color: #b5e5fa !important;
}
.MuiSelect-icon {
    color: var(--secondary) !important;
}
.MuiCheckbox-root .MuiSvgIcon-root {
    width: 28px;
    height: 28px;
}
.MuiCheckbox-root input,
.MuiSwitch-root input,
.MuiRadio-root input {
    opacity: 0;
}
.MuiCheckbox-root.Mui-checked {
    color: var(--secondary) !important;
}
.MuiRadio-root.Mui-checked {
    color: var(--secondary) !important;
}
.MuiOutlinedInput-root .MuiSelect-select {
    padding-left: 14px !important;
}
.MuiOutlinedInput-root .MuiSelect-select + input {
    padding: 16px 14px;
    font-size: 17px;
}
.MuiList-root {
    max-height: 254px;
    overflow: auto;
}
.MuiList-root .MuiMenuItem-root {
    font-size: 14px;
    padding: 15px 25px !important;
    text-align: center;
    position: relative;
}
.MuiList-root .MuiMenuItem-root:not(:last-child)::after {
    position: absolute;
    content: "";
    background-color: #ebebeb;
    height: 2px;
    left: 25px !important;
    width: calc(100% - 50px) !important;
    bottom: 0px;
}
.MuiList-root .MuiMenuItem-root.Mui-selected {
    color: var(--secondary) !important;
    font-weight: bold;
}
.MuiDialog-container .MuiPaper-root {
    border-radius: 10px;
}
.required-astrik {
    position: relative;
    display: inline-block;
    width: fit-content;
}
.required-astrik::after {
    content: "*";
    position: absolute;
    right: -9px;
    top: -2px;
    font-size: 20px;
    color: red;
}
h4.required {
    position: relative;
    display: inline-block;
    width: fit-content;
}
h4.required::after {
    content: "*";
    position: absolute;
    right: -9px;
    top: -2px;
    font-size: 20px;
    color: red;
    line-height: 1.2;
}
.image-uploader {
    width: 110px;
    height: 110px;
    position: relative;
}
.image-uploader .uploaded-img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}
.image-uploader .camera-icon {
    position: absolute;
    bottom: 2px;
    right: -3px;
    cursor: pointer;
}
@media screen and (max-width: 820px) {
    .image-uploader {
        width: 90px;
        height: 90px;
        position: relative;
    }
}
@media screen and (max-width: 576px) {
    .image-uploader {
        width: 70px;
        height: 70px;
        position: relative;
    }
    .image-uploader .camera-icon {
        width: 25px;
    }
    span.color-primary.underline.pointer,
    a {
        font-size: 14px;
    }
}
fieldset {
    border: 2px solid var(--input-border) !important;
}
.MuiInput-underline::before,
.MuiInput-underline::after {
    border-bottom: 2px solid var(--input-border) !important;
}
.MuiFormHelperText-root.Mui-error {
    margin-left: 0px !important;
    margin-right: 0px !important;
    position: absolute;
    top: -34px;
    right: -0px;
    font-size: 16px;
}
.page-reset .MuiFormHelperText-root.Mui-error {
    position: inherit;
    top: auto;
    text-align: right;
}
.chip-label {
    flex-direction: row !important;
    flex-wrap: wrap;
    margin: -7px -20px;
}
.chip-label label {
    display: inline-block;
    min-width: 150px;
    background: #e7f6ff;
    width: fit-content;
    padding: 12px 20px;
    border-radius: 40px;
    font-size: 20px;
    line-height: 22px;
    font-weight: bold;
    margin: 7px 20px !important;
    text-align: center;
}
.react-datepicker__input-container::after {
    position: absolute;
    content: "▼";
    top: 50%;
    right: 15px;
    color: var(--secondary);
    font-size: 18px;
    transform: translateY(-50%) scaleY(0.6);
    cursor: pointer;
}
@media screen and (min-width: 1600px) {
    .chip-label label .MuiTypography-root {
        font-size: 20px;
    }
    .react-datepicker__input-container::after {
        font-size: 22px;
    }
}
.chip-label label.active {
    background-color: var(--primary);
    color: var(--white);
}
.chip-label label .MuiRadio-root {
    width: 0px;
    padding: 0px;
}
.chip-label label .MuiRadio-root span {
    display: none;
}
.chip-label label .MuiTypography-root {
    font-weight: bold;
}
.MuiFormHelperText-root.Mui-error {
    color: #fd0d1b !important;
    font-family: inherit;
}
.error-text {
    line-height: 1.66;
    letter-spacing: 0.03333em;
    color: #fd0d1b;
    font-size: 16px;
}

.MuiMenuItem-root {
    padding: 8px 20px !important;
    display: block !important;
}
.MuiBreadcrumbs-root {
    width: 86%;
    margin: auto !important;
    padding: 25px 0px 15px;
}
.MuiAccordion-rounded {
    border: 2px solid var(--input-border);
    background-color: white !important;
    box-shadow: none !important;
}
.MuiAccordion-rounded:not(:last-child) {
    margin-bottom: 20px;
}
.MuiAccordion-rounded .MuiButtonBase-root.Mui-expanded {
    min-height: auto;
}
.MuiAccordion-rounded .MuiButtonBase-root.Mui-expanded {
    min-height: auto;
}
.MuiAccordion-rounded .MuiAccordionSummary-content {
    margin: 20px 0px;
}
.MuiAccordion-rounded .MuiAccordionDetails-root {
    background: #f6fbfe;
    padding: 22px 18px;
}
.container {
    width: 86%;
    margin: auto;
}
.MuiSwitch-switchBase .MuiSwitch-thumb {
    background-color: var(--red);
}
.MuiSwitch-switchBase +  .MuiSwitch-track {
    background-color: var(--red);
}
.MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb {
    background-color: var(--green);
}
.MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    background-color: var(--green) !important;
}
.react-datepicker-wrapper input {
    padding: 16.5px 14px;
    border-radius: 5px;
    border: 2px solid var(--input-border);
    font-size: 16px;
    width: 100%;
}
.react-datepicker__triangle {
    transform: translate(210px, 0px) !important;
}
.MuiInput-underline input,
.MuiSelect-select {
    padding-left: 0px !important;
    padding-right: 0px !important;
}
.virtual-placeholder {
    position: absolute;
    color: #b5b9bb;
    margin-top: 6px;
    font-weight: 100 !important;
    font: inherit;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
.virtual-placeholder.for-outline {
    margin-top: 20px;
    margin-left: 15px;
}
.input-pl-0 input{
    padding-left: 0px !important;
}
@media screen and (min-width: 1600px) {
    .virtual-placeholder {
        font-size: 20px;
        margin-top: 30px;
    }
    .virtual-placeholder.for-outline {
        margin-top: 25px;
        font-size: 22px;
        margin-left: 20px;
    }
    .MuiFormHelperText-root.Mui-error,
    .error-text {
        font-size: 18px !important;
        top: -35px;
    }
    input,
    .MuiSelect-select {
        font-size: 21px !important;
        padding: 24px !important;
    }
    .MuiOutlinedInput-root .MuiSelect-select + input {
        padding: 24.5px !important;
    }
    /* .MuiInputBase-inputAdornedStart{
        padding-left: 5px !important;
    } */
    .MuiInput-underline input,
    .MuiSelect-select {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
}

@media screen and (max-width: 576px) {
    .container {
        width: 100%;
    }
    .MuiBreadcrumbs-root {
        width: 92%;
        padding: 8px 0px 8px;
    }
    .MuiBreadcrumbs-root h3,
    .MuiBreadcrumbs-root .MuiTypography-root {
        font-size: 12px;
    }
    .MuiTextField-root input,
    .MuiSelect-select,
    .react-datepicker-wrapper input {
        padding: 12px 14px !important;
        font-size: 14px !important;
    }
    .MuiSelect-select + input {
        padding: 14px 14px !important;
        font-size: 14px !important;
    }
    .MuiFormHelperText-root.Mui-error,
    .error-text {
        font-size: 11px !important;
        top: -26px;
    }
    .MuiInput-underline input,
    .MuiSelect-select {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .chip-label {
        margin: -5px -7px;
    }
    .chip-label label {
        min-width: 75px;
        padding: 7px 15px;
        font-size: 14px;
        line-height: 16px;
        margin: 5px 7px !important;
    }
    .chip-label label .MuiTypography-root {
        font-size: 14px;
    }
    .MuiCheckbox-root .MuiSvgIcon-root {
        width: 20px;
        height: 20px;
    }
    .MuiCheckbox-root .MuiTypography-root {
        font-size: 14px;
    }
}

@media screen and (max-width: 450px) {
    .toggle-cover .MuiTypography-root {
        font-size: 12px;
    }
    .chip-label label {
        min-width: 60px;
        padding: 3px 10px;
        font-size: 12px;
        line-height: 12px;
        margin: 5px 7px !important;
    }
    .chip-label label .MuiTypography-root {
        font-size: 12px;
    }
}

.react-datepicker-popper{
    z-index: 99 !important;
}
.MuiModal-root{
    z-index: 99 !important;
}