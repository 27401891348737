.page-privacy .privacy-content{
    padding: 40px;
    border-radius: 10px;
}
.privacy-content h2, .privacy-content h3, .privacy-content li {
    line-height: 1.7 !important;
}
.privacy-content ol{
    padding-left: 30px;
}
.privacy-content ol li{
    list-style-type: auto;
}
.privacy-content ul{
    padding-left: 25px;
}
.privacy-content ul li{
    list-style-type: disc;
}
.privacy-content a{
    color: #fb7122;
    border-color: #fb7122;
}

@media screen and (max-width: 767px) {
    .page-privacy .privacy-content {
        padding: 15px 20px;
    }
}