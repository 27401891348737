body {
  margin: 0;
  font-family: "sf_pro_displayregular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.font_medium{
  font-family: "sf_pro_displaymedium";
}
.dialog-for-otp {
  top: 80px !important;
}

.dialog-for-otp .MuiBackdrop-root {
  background-color: transparent !important;
}
.dialog-for-otp.comp-filters{
  z-index: 999;
}
.dialog-for-otp.comp-filters .MuiPaper-root{
  background-color: #f6f6f6;
}

@media screen and (min-width: 1600px) {
  .dialog-for-otp {
    top: 107px !important;
  }
}
@media screen and (max-width: 576px) {
  .dialog-for-otp {
    top: 56px !important;
  }
}