.page-add-broker {
  background-color: #f6f6f6;
  min-height: calc(100vh - 80px);
}
.page-add-broker .MuiBreadcrumbs-root {
  margin-left: 0px !important;
}
.page-add-broker .add-comp-content {
  padding: 40px;
  border-radius: 10px;
}

.page-add-broker .search-cover .search-input {
  width: 100%;
  position: relative;
}
.page-add-broker .search-cover .search-input .search {
  padding: 15px 50px;
  border-radius: 40px;
  width: 100%;
  border: 1px solid var(--primary);
  font-size: 18px;
  color: #666666;
}
.page-add-broker .search-cover .search-input .search-icon {
  position: absolute;
  left: 20px;
  top: 15px;
  width: 20px;
}
.page-add-broker .search-cover .search-input .close-icon {
  position: absolute;
  right: 20px;
  top: 15px;
  width: 20px;
}
@media screen and (min-width: 1600px) {
  .page-add-broker .search-cover .search {
    padding: 18px 50px !important;
  }
  .page-add-broker .search-cover .search-input .search-icon {
    top: 18px;
    width: 23px;
  }
  .page-add-broker .search-cover .search-input .close-icon {
    top: 18px;
    width: 23px;
  }
}
/* .page-add-broker #scrollableDiv {
    overflow-y: scroll;
    max-height: 500px;
    width: calc(100% + 42px);
    padding-right: 10px;
    overflow-x: hidden;
} */
.page-add-broker .single-broker {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-right: 25px;
  padding-bottom: 20px;
  border-bottom: 1px solid #bcbec0;
  position: relative;
  flex-wrap: wrap;
}
.page-add-broker .single-broker .broker-img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 20px;
}
.page-add-broker .single-broker .broker-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.page-add-broker .single-broker .radio-btn {
  position: absolute;
  right: 0px;
  top: 12px;
  width: 20px;
  height: 20px;
}

.page-add-broker .single-broker .editing-cover {
  width: 100%;
  text-align: right;
}
.page-add-broker .single-broker .editing-cover label {
  margin-right: -25px;
  margin-bottom: -15px;
}

.page-add-broker .infinite-scroll-component {
  overflow-x: hidden !important;
  padding: 30px 11px 0px 0px;
}

.page-add-broker.infinite-scroller {
  overflow-y: scroll;
  max-height: 500px;
  width: calc(100% + 32px);
  padding-right: 10px;
  overflow-x: hidden;
}

@media screen and (max-width: 768px) {
  .page-add-broker .add-comp-content {
    padding: 30px;
  }
}
@media screen and (max-width: 700px) {
  .page-add-broker .space-between.flex-wrap {
    padding: 0px 4%;
  }
  .page-add-broker .space-between.flex-wrap nav {
    width: 100%;
  }
  .page-add-broker .space-between.flex-wrap > div {
    display: block;
    width: 100%;
    text-align: right;
    margin-bottom: 10px;
    margin-top: -11px;
    font-size: 12px;
  }
}
@media screen and (max-width: 576px) {
  .page-add-broker .search-cover {
    padding-right: 0px;
    border-right: none;
  }
  .page-add-broker .search-cover .search {
    font-size: 12px !important;
    padding: 6px 25px !important;
  }
  .page-add-broker .search-cover .search-input .search-icon {
    top: 9px;
    width: 10px;
    left: 10px;
  }
  .page-add-broker .search-cover .search-input .close-icon {
    top: 9px;
    width: 10px;
    right: 10px;
  }
  .page-add-broker .add-comp-content {
    padding: 20px;
  }
  .page-add-broker .single-broker .radio-btn {
    right: 7px;
  }
  .page-add-broker .single-broker .broker-img {
    width: 50px;
    height: 50px;
  }
  /* .page-add-broker #scrollableDiv{
        width: calc(100% + 20px);
    } */
  .page-add-broker .infinite-scroll-component {
    padding-top: 0px;
  }
}

.page-add-broker .single-broker .MuiFormControlLabel-root {
  flex: 1;
  height: 70px;
  margin-left: 10px;
  position: inherit;
  margin-right: -30px;
}

.page-add-broker .container .link-text {
  color: var(--primary);
  font-weight: 600;
  cursor: pointer;
}
