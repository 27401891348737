.page-invite-broker {
  background-color: #f6f6f6;
}
.page-invite-broker .MuiBreadcrumbs-root {
  margin-left: 0px !important;
}
.page-invite-broker .add-comp-content {
  padding: 40px;
  border-radius: 10px;
}

.page-invite-broker .single-broker .radio-btn {
  position: absolute;
  right: 0px;
  top: 12px;
  width: 20px;
  height: 20px;
}

.page-invite-broker .single-broker .editing-cover {
  width: 100%;
  text-align: right;
}
.page-invite-broker .single-broker .editing-cover label {
  margin-right: -25px;
  margin-bottom: -15px;
}

@media screen and (max-width: 768px) {
  .page-invite-broker .add-comp-content {
    padding: 30px;
  }
}
@media screen and (max-width: 700px) {
  .page-invite-broker .space-between.flex-wrap {
    padding: 0px 4%;
  }
  .page-invite-broker .space-between.flex-wrap nav {
    width: 100%;
  }
  .page-invite-broker .space-between.flex-wrap > div {
    display: block;
    width: 100%;
    text-align: right;
    margin-bottom: 10px;
    margin-top: -11px;
  }
  .page-invite-broker .space-between.flex-wrap > div .MuiTypography-root {
    font-size: 14px;
  }
}
@media screen and (max-width: 576px) {
  .page-invite-broker .add-comp-content {
    padding: 20px;
  }
}
