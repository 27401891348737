.page-add-broker {
    background-color: #f6f6f6;
    min-height: calc(100vh - 80px);
}
.page-add-broker .MuiBreadcrumbs-root {
    margin-left: 0px !important;
}
.page-add-broker .add-comp-content {
    padding: 40px;
    border-radius: 10px;
}
.page-add-broker #scrollableDiv {
    overflow-y: scroll;
    max-height: 500px;
    width: calc(100% + 42px);
    padding-right: 10px;
    overflow-x: hidden;
}
.page-add-broker .single-broker {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 20px;
    border-bottom: 1px solid #bcbec0;
    position: relative;
    flex-wrap: wrap;
}
.page-add-broker .single-broker .broker-img {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 20px;
}
.page-add-broker .single-broker .broker-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.page-add-broker .single-broker .radio-btn {
    position: absolute;
    right: 0px;
    top: 12px;
    width: 20px;
    height: 20px;
}

.page-add-broker .single-broker .editing-cover {
    width: 100%;
    text-align: right;
}
.page-add-broker .single-broker .editing-cover label {
    margin-right: 0px !important;
    margin-bottom: -15px;
}

.page-add-broker .infinite-scroll-component {
    overflow-x: hidden !important;
    padding: 30px 11px 0px 0px;
}

@media screen and (max-width: 768px) {
    .page-add-broker .add-comp-content {
        padding: 30px;
    }
}
@media screen and (max-width: 700px) {
    .page-add-broker .space-between.flex-wrap{
        padding: 0px 4%
    }
    .page-add-broker .space-between.flex-wrap nav{
        width: 100%
    }
    .page-add-broker .space-between.flex-wrap > div{
        display: block;
        width: 100%;
        text-align: right;
        margin-bottom: 10px;
        margin-top: -11px;
        font-size: 12px;;
    }
}
@media screen and (max-width: 576px) {
    .page-add-broker .add-comp-content {
        padding: 20px;
    }
    .page-add-broker .single-broker .radio-btn{
        right: 7px;
    }
    .page-add-broker .single-broker .broker-img{
        width: 50px;
        height: 50px;
    }
    .page-add-broker #scrollableDiv{
        width: calc(100% + 20px);
    }
    .page-add-broker .infinite-scroll-component{
        padding-top: 0px;
    }
}






















.custom-radio {
    position: relative;
    cursor: pointer;
    font-weight: 600;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.custom-radio input {
    opacity: 0;
}

.custom-radio .check-radio {
    position: absolute;
    top: 10px;
    right: 0px;
    height: 20px;
    width: 20px;
    background-color: var(--white);
    border: 1px solid #b2b2b2;
    border-radius: 100px;
    border-radius: 50%;
    cursor: pointer;
}

.custom-radio input:checked ~ .check-radio {
    background-color: var(--white);
    border: 1px solid var(--primary);
}

.custom-radio input:checked ~ .check-radio.options {
    border: 1px solid var(--primary);
}

.custom-radio .check-radio:after,
.custom-radio .check-radio:after {
    content: "";
    position: absolute;
    display: none;
}

.custom-radio input:checked ~ .check-radio:after,
.custom-radio input:checked ~ .check-radio:after {
    display: block;
}

.custom-radio .check-radio:after {
    top: 50%;
    left: 50%;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background-color: var(--primary);
    transform: translate(-50%, -50%);
}
.page-add-broker .single-broker .editing-cover{
    margin-top: -10px;
}