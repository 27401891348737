.page-setting .setting-content {
    padding: 20px 40px;
    border-radius: 10px;
}
.page-setting .container .space-between {
    padding: 40px 0px;
    border-bottom: 2px solid #ebebeb;
    font-size: 20px;
    height: 108px;
}
@media screen and (max-width: 767px) {
    .page-setting .setting-content {
        padding: 15px 20px;
    }
    .page-setting .container .space-between {
        padding: 10px 0px 25px;
        height: auto;
        flex-wrap: wrap;
    }
    .page-setting .container .space-between.rent {
        flex-direction: column;
        align-items: flex-start;
    }
    .page-setting .radio-tabs{
        margin-top: 20px;
    }
}
.page-setting .container .space-between .icon-cover {
    min-width: 35px;
}
.page-setting .container .space-between .icon-cover img {
    display: table;
}
.page-setting .container .space-between .MuiSvgIcon-root {
    color: var(--secondary);
    cursor: pointer;
}

.page-setting .radio-tabs {
    flex-direction: row !important;
    width: max-content;
}
.page-setting .lease-sale-tab {
    margin: 16px 0px 16px 30px;
    padding-right: 30px;
    border-right: 1px solid #d3d3d3;
}
.page-setting .radio-tabs label {
    width: 150px;
    padding: 16px 20px;
    display: inline-block;
    background: #e7f6ff;
    margin: 0px;
    color: var(--primary);
    text-align: center;
}
.page-setting .radio-tabs label:first-child {
    border-radius: 40px 0px 0px 40px;
}
.page-setting .radio-tabs label:last-child {
    border-radius: 0px 40px 40px 0px;
}
.page-setting .radio-tabs label.active {
    background-color: var(--primary);
    color: var(--white);
}
.page-setting .radio-tabs.small label {
    padding: 6px 20px;
    font-size: 16px;
    line-height: 20px;
    width: max-content;
}
.page-setting .radio-tabs label .MuiTypography-root {
    font-weight: bold !important;
    font-size: 18px;
}
.page-setting .radio-tabs.small label .MuiTypography-root {
    font-weight: 500 !important;
    font-size: 16px;
}
.page-setting .radio-tabs label .MuiRadio-root {
    width: 0px;
    padding: 0px;
}
.page-setting .radio-tabs label .MuiRadio-root span {
    display: none;
}
