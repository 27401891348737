.page-notification {
  padding: 0px 0px;
}

.page-notification .infinite-scroller {
  overflow-y: scroll;
  max-height: calc(100vh - 240px);
  width: calc(100% + 32px);
  padding-right: 10px;
  overflow-x: hidden;
}

@media screen and (min-width: 1600px) {
  .page-notification .infinite-scroller {
    max-height: calc(100vh - 280px);
  }
}

.page-notification .notification-list .single-notification {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
}
.page-notification .bell-icon {
  width: 50px;
  height: 50px;
  margin-right: 15px;
  border-radius: 50%;

}
.page-notification .cross-icon {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  width: 25px;
}
.page-notification .noti-detail {
  flex: 1;
  padding-right: 100px;
}
.page-notification .noti-detail .time-stamp {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-track {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.05) !important;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: var(--primary) !important;
}

@media screen and (max-width: 576px) {
  .page-notification .infinite-scroller {
    width: 100% !important;
    max-height: calc(100vh - 180px);
  }
  .page-notification .notification-list .single-notification {
    padding: 15px;
  }
  .page-notification .noti-detail .time-stamp {
    position: inherit;
  }
  .page-notification .cross-icon{
    top: 10px;
    right: 10px;
    width: 20px;
  }
  .page-notification .noti-detail{
    padding-right: 0px;
  }
  .page-notification .noti-detail h3{
    padding-right: 20px;
  }
  .page-notification .bell-icon {
    width: 40px;
    height: 40px;
  }
}