.page-profile .container {
  background-color: white;
  padding: 30px;
}
.page-profile h1 {
  font-size: 34px;
}

.page-profile .container .profile-cover {
  width: 110px;
  height: 110px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 50px;
}
.page-profile .container .profile-cover img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.page-profile .btn-secondary {
  width: 450px;
  max-width: 100%;
}
.add-lic-cover.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* .address-dialog-popup .search-input {
  width: 100%;
  position: relative;
}
.address-dialog-popup .search {
  padding: 15px calc(50px + 1.7vw) 15px 50px !important;
  border-radius: 40px;
  width: 100%;
  border: 1px solid var(--primary);
  font-size: 18px;
  color: #666666;
}
.address-dialog-popup .search-input .search-icon {
  position: absolute;
  left: 20px;
  top: 15px;
  width: 20px;
}
.address-dialog-popup .search-input .target-icon {
  position: absolute;
  right: 20px;
  top: 15px;
  width: calc(20px + 1.7vw);
  border-left: 2px solid #c8e9fd;
  padding: 1px 0px 1px 1.7vw;
  cursor: pointer;
}

@media screen and (min-width: 1600px) {
  .address-dialog-popup .search {
    padding: 16px calc(50px + 2.5vw) 16px 50px !important;
  }
  .address-dialog-popup .search-input .search-icon {
    top: 17px;
    width: 23px;
  }
  .address-dialog-popup .search-input .target-icon {
    top: 17px;
    width: calc(23px + 1.7vw);
  }
} */

.address-dialog-popup .search-input {
  width: 100%;
  position: relative;
}
.address-dialog-popup .search {
  font-size: 16px;
  padding: 16.5px 30px 16.5px 0px !important;
  border-radius: 0px;
  width: 100%;
  line-height: 1.2;
  border: none;
  border-bottom: 2px solid var(--input-border);
  font-weight: 700;
}
.address-dialog-popup .search-input .target-icon {
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  width: 22px;
}
@media screen and (min-width: 1600px) {
  .address-dialog-popup .search {
    font-size: 21px;
    padding: 24px 30px 24px 0px !important;
  }
  .address-dialog-popup .search-input .target-icon {
    width: 24px;
  }
}

@media screen and (max-width: 820px) {
  .page-profile h1 {
    font-size: 28px;
  }
}
@media screen and (max-width: 576px) {
  .page-profile .container {
    padding: 20px;
  }
  .page-profile h1 {
    font-size: 22px;
  }
}
