.page-subscription {
    width: 1700px;
    max-width: 100%;
    margin: auto;
    padding-bottom: 30px;
}
@media screen and (max-width: 576px) {
    .page-subscription {
        padding-left: 7%;
        padding-right: 7%;
    }
}
.page-subscription .container .bg-white {
    padding: 20px 30px;
    background-color: white;
    border-radius: 10px;
}
.subscription-card {
    padding: 10px 30px 40px;
    background-color: white;
    border-radius: 0px 0px 40px 40px;
    position: relative;
    max-width: 450px;
    margin-left: auto;
    margin-right: auto;
}
.subscription-card .space-between {
    min-height: 63px;
}
.subscription-card::before {
    content: "";
    top: -30px;
    left: -1px;
    right: -1px;
    position: absolute;
    background-image: url("./../../assets/icons/subs-cornor-white.svg");
    width: calc(100% + 1px);
    height: 30px;
    background-repeat: no-repeat;
    background-size: cover;
}
.subscription-card ul {
    border-top: 2px solid #e6e6e6;
    border-bottom: 2px solid #e6e6e6;
    margin: 30px 0px;
    padding: 20px 0px;
}
.subscription-card ul li {
    padding: 10px 0px 10px 30px;
    position: relative;
}
.subscription-card ul li::before {
    content: "";
    position: absolute;
    background-image: url("./../../assets/icons/check-orange.svg");
    background-repeat: no-repeat;
    background-size: cover;
    left: 0px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
}

.subscription-card.hoverable-card:hover {
    color: var(--white);
    background-color: var(--primary);
}
.subscription-card.hoverable-card:hover::before {
    background-image: url("./../../assets/icons/subs-cornor-blue.svg");
}
.subscription-card.hoverable-card:hover .color-primary {
    color: var(--white);
}
.subscription-card.hoverable-card:hover ul li::before {
    background-image: url("./../../assets/icons/check-white.svg");
}
.subscription-card.hoverable-card:hover .btn-secondary {
    background-color: var(--white);
    color: var(--primary);
    border-color: var(--white);
}
.added-card .card-img {
    width: 55px;
    height: 55px;
    object-fit: cover;
    margin-right: 15px;
}
.added-card .card-detail {
    flex: 1;
}
.added-card .card-detail .card-info {
    flex: 1;
}
.added-card .card-detail .card-info .btn-secondary{
    width: 300px;
    max-width: 100%;
}

@media screen and (max-width: 1200px) {
    .added-card .card-detail .card-info .btn-secondary {
        width: auto;
    }
}
@media screen and (max-width: 576px) {
    .subscription-card{
        padding: 0px 20px 30px;
        border-radius: 0px 0px 30px 30px;
    }
    .page-subscription .container .bg-white {
        padding: 15px 20px;
    }
    .added-card .card-img {
        width: 40px;
        height: 40px;
        margin-right: 10px;
    }
    .added-card .card-detail {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }
    .added-card .card-detail .card-info .btn-secondary {
        width: 100%;
        margin-bottom: 20px;
    }
}

.page-subscription .back-icon {
    color: var(--primary);
    background-color: #e7f6ff;
    padding: 5px;
    margin-right: 10px;
    border-radius: 5px;
    width: 34px;
    height: 34px;
    cursor: pointer;
}