.page-comp-detail .comp-detail-header {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;
    border-bottom: 1px solid #d3d3d3;
    border-radius: 10px 10px 0px 0px;
    overflow: hidden;
}
.page-comp-detail .comp-detail-header .property-image-cover {
    width: 550px;
    height: 218px;
    position: relative;
}
.page-comp-detail .comp-detail-header .property-image-cover .prop-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.page-comp-detail .comp-detail-header .property-image-cover .image-content {
    position: absolute;
    bottom: 20px;
    left: 20px;
    width: calc(100% - 40px);
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.page-comp-detail .comp-detail-header .property-image-cover .image-content h3 {
    text-transform: uppercase;
    border-radius: 20px;
    color: var(--primary);
    background-color: #e7f6ff;
    padding: 5px 20px;
    font-weight: bold;
}
.page-comp-detail .comp-detail-header .property-image-cover .image-content img:first-child {
    padding-right: 30px;
    border-right: 1px solid #eeeeee;
    margin-right: 30px;
}
.page-comp-detail .comp-detail-header .right-portion {
    padding: 40px 40px 0px 40px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex: 1;
}
.page-comp-detail .comp-detail-header .right-portion .icons-cover img:first-child {
    padding-right: 40px;
    border-right: 1px solid #d3d3d3;
    margin-right: 40px;
}
.page-comp-detail .comp-detail-header .steps-tab .MuiTabs-flexContainer {
    justify-content: space-between;
}
.page-comp-detail .comp-detail-header .steps-tab .MuiTabs-flexContainer .MuiButtonBase-root {
    font-size: 16px;
    padding: 33px 16px;
    text-transform: capitalize;
}
@media screen and (min-width: 1600px) {
    .page-comp-detail .comp-detail-header .steps-tab .MuiTabs-flexContainer .MuiButtonBase-root {
        font-size: 20px;
    }

}
.page-comp-detail .comp-detail-header .steps-tab .MuiTabs-flexContainer .MuiButtonBase-root.Mui-selected {
    font-weight: 600;
    color: var(--secondary);
}
.page-comp-detail .comp-detail-header .steps-tab .MuiTabs-indicator {
    background-color: var(--secondary);
}
.page-comp-detail .comp-detail-body {
    padding: 20px 30px;
}
.page-comp-detail .comp-detail-body .btn-cover {
    flex-wrap: wrap;
}
.page-comp-detail .comp-detail-body .btn-cover .btn {
    min-width: 200px;
}

.page-comp-detail .tenants-cover {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
}
.page-comp-detail .add-tenant-cover {
    border: 2px solid var(--input-border);
    padding: 25px;
    border-radius: 8px;
    background-color: var(--white);
    display: flex;
    align-items: flex-start;
    text-align: center;
    margin: 10px 30px;
    justify-content: center;
    overflow: hidden;
    min-height: 260px;
    width: 266px;
}
.page-comp-detail .tenant-added {
    width: 100%;
}
.page-comp-detail .add-tenant-cover .tenant-img{
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin: 10px auto;
    overflow: hidden;
}
.page-comp-detail .add-tenant-cover .tenant-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.page-comp-detail .badge {
    display: inline-block;
    min-width: 150px;
    background: #e7f6ff;
    color: #73767c;
    width: fit-content;
    padding: 12px 20px;
    border-radius: 40px;
    font-size: 20px;
    line-height: 22px;
    font-weight: bold;
    margin-top: 2px;
    text-align: center;
}
.page-comp-detail .sale-price-cover {
    width: 90px;
    height: 40px;
    border-radius: 5px;
    background-color: var(--input-border);
    color: white;
    font-size: 18px;
    font-weight: bold;
}
@media screen and (max-width: 991px) {
    .page-comp-detail .comp-detail-header .property-image-cover {
        width: 100%;
        height: 290px;
    }
    .page-comp-detail .comp-detail-header .right-portion {
        width: 100%;
    }
    .page-comp-detail .add-tenant-cover {
        margin: 5px 10px;
        padding: 10px 15px;
        width: calc(100% / 3 - 20px);
    }
    .page-comp-detail .add-tenant-cover h3{
        font-size: 14px;
    }
    .page-comp-detail .add-tenant-cover h2{
        font-size: 15px;
    }
    .page-comp-detail .add-tenant-cover p{
        font-size: 12px;
    }
    .page-comp-detail .add-tenant-cover .tenant-img{
        width: 60px;
        height: 60px;
        border-radius: 50%;
        margin: 5px auto;
        overflow: hidden;
    }
}
@media screen and (max-width: 768px) {
    .page-comp-detail .comp-detail-header .property-image-cover {
        height: 230px;
    }
    .page-comp-detail .comp-detail-body {
        padding: 15px 20px;
    }
    .page-comp-detail .tenants-cover{
        padding: 10px 5px;
    }
}
@media screen and (max-width: 700px) {
    .page-comp-detail .add-tenant-cover{
        padding: 5px 8px 10px;
    }
}
@media screen and (max-width: 576px) {
    .page-comp-detail .comp-detail-header .property-image-cover {
        width: 100%;
        height: 190px;
    }
    .page-comp-detail .comp-detail-header .property-image-cover .image-content img {
        width: 15px;
    }
    .page-comp-detail .comp-detail-header .property-image-cover .image-content img:first-child {
        padding-right: 20px;
        margin-right: 20px;
        width: 40px;
    }
    .page-comp-detail .comp-detail-header .property-image-cover .image-content h3 {
        font-size: 12px;
        padding: 2px 12px;
    }
    .page-comp-detail .comp-detail-header .property-image-cover .image-content {
        bottom: 10px;
        left: 10px;
        width: calc(100% - 20px);
    }
    .page-comp-detail .comp-detail-header .right-portion {
        width: 100%;
        padding: 20px 20px 0px 20px;
    }
    .page-comp-detail .comp-detail-header .steps-tab {
        margin-top: -20px;
    }
    .page-comp-detail .comp-detail-header .steps-tab .MuiTabs-flexContainer .MuiButtonBase-root {
        padding: 15px 12px;
        font-size: 14px;
    }
    .page-comp-detail .comp-detail-header .right-portion .icons-cover img {
        width: 30px;
    }
    .page-comp-detail .comp-detail-header .right-portion .icons-cover img:first-child {
        padding-right: 20px;
        margin-right: 20px;
        width: 50px;
    }
    .page-comp-detail .badge {
        min-width: 75px;
        padding: 7px 15px;
        font-size: 14px;
        line-height: 16px;
    }
    .page-comp-detail .sale-price-cover {
        width: 40px;
        height: 23px;
        font-size: 12px;
    }
    .page-comp-detail .tenants-cover{
        overflow-x: auto;
        max-width: 100%;
    }
    .page-comp-detail .add-tenant-cover{
        min-width: 150px;
    }
}
@media screen and (max-width: 450px) {
    .page-comp-detail .comp-detail-header .property-image-cover {
        width: 100%;
        height: 150px;
    }
}
