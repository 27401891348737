.landing .header-cover {
    position: sticky;
    z-index: 99;
    top: 0;
}
.landing main {
    min-height: calc(100vh - 80px);
    background-color: #f6f6f6;
}
@media screen and (min-width: 1600px) {
    .landing main {
        min-height: calc(100vh - 107px);
    }
}
@media screen and (max-width: 576px) {
    .landing main {
        min-height: 100vh;
        background-color: #f6f6f6;
    }
}