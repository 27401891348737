.popup-ui {
    padding: 30px;
    text-align: center;
    width: 550px;
    max-width: 100%;
}
@media screen and (max-width: 450px){
    .popup-ui{
        padding: 20px;
    }
}
.popup-ui .close-icon {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    font-size: 24px;
}
.popup-ui .icon-cover {
    margin: 0px auto 30px;
}
.popup-ui h3 {
    width: 360px;
    max-width: 100%;
    margin: auto;
}

.image-dialog li {
    padding: 24px 0px;
    border-bottom: 1px solid #d3d3d3;
    font-size: 18px;
}
.image-dialog li .justify-start {
    cursor: pointer;
    display: inline-flex;
    font-weight: bold;
}
.image-dialog a {
    font-size: 20px;
    font-weight: bold;
}

.popup-ui.alert-popup{
    width: 380px;
}
.popup-ui.alert-popup h3{
    margin-top: 20px;
}