.image-dialog li{
    padding: 24px 0px;
    border-bottom: 1px solid #d3d3d3;
    font-size: 18px;
}
.image-dialog li .justify-start{
    cursor: pointer;
    display: inline-flex;
    font-weight: bold;
}
.image-dialog a{
    font-size: 20px;
    font-weight: bold;
}