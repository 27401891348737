.home-page {
    background-color: #254089;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 0px 0px 7%;
    min-height: calc(100vh - 80px);
    z-index: 99;
    position: relative;
}
@media screen and (min-width: 1600px) {
    .home-page {
        min-height: calc(100vh - 107px);
    }
}
.home-page .left-portion {
    width: 540px;
}

.home-page .left-portion h1 {
    font-size: 59px;
    font-weight: bold;
    padding-bottom: 40px;
    letter-spacing: 3px;
    line-height: 1.2;
}
.home-page .left-portion p {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 50px;
}
.home-page .btn {
    display: block;
    width: 250px;
    padding: 20px 0px;
    text-align: center;
}
.home-page .image-cover {
    width: calc(100% - 520px);
    text-align: right;
}

.home-page .image-cover img {
    width: auto;
    height: calc(100vh - 90px);
    object-fit: cover;
    min-height: 400px;
}

@media screen and (min-width: 1600px) {
    .home-page .left-portion {
        width: 635px;
    }
    .home-page .left-portion h1 {
        font-size: 69px;
    }
    .home-page .left-portion p {
        font-size: 30px;
        margin-bottom: 80px;
    }
    .home-page .btn {
        width: 300px;
    }
    .home-page .image-cover {
        width: calc(100% - 570px);
    }
    .home-page .image-cover img {
        height: calc(100vh - 111px);
    }
}
@media screen and (max-width: 1200px) {
    .home-page .left-portion {
        width: 410px;
    }
    .home-page .left-portion h1 {
        font-size: 42px;
    }
    .home-page .left-portion p {
        font-size: 20px;
        margin-bottom: 50px;
    }
    .home-page .btn {
        width: 200px;
    }
    .home-page .image-cover {
        width: calc(100% - 370px);
    }
    .home-page .image-cover img {
        height: calc(100vh - 111px);
    }
}

@media screen and (max-width: 992px) {
    .home-page{
        flex-direction: column-reverse;
        padding: 0px;
    }
    .home-page .left-portion {
        width: 500px;
        margin: auto;
        max-width: 80%;
        padding-bottom: 100px;
    }
    .home-page .left-portion h1 {
        font-size: 50px;
    }
    .home-page .left-portion p {
        font-size: 20px;
        margin-bottom: 50px;
    }
    .home-page .image-cover {
        width: 100%;
        padding-top: 100px;
        text-align: center !important;
    }
    .home-page .image-cover img {
        width: 70%;
        height: 100%;
    }
}
@media screen and (max-width: 767px) {
    .home-page {
        flex-direction: row;
    }
    .home-page .image-cover {
        display: none;
    }
}