.page-dashboard .download-app-cover {
  background-color: var(--input-border);
  color: var(--white);
}
.page-dashboard .download-app-cover .container {
  padding: 20px 30px 20px 30px;
  position: relative;
  text-align: center;
  letter-spacing: 2px;
}
.page-dashboard .download-app-cover .close-icon {
  position: absolute;
  right: -5px;
  top: 50%;
  font-size: 30px;
  cursor: pointer;
  transform: translateY(-50%);
}
.page-dashboard .floating-add-icon {
  position: fixed;
  right: 7%;
  bottom: 40px;
  z-index: 99;
  transform: translateX(50%);
  cursor: pointer;
}
.page-dashboard .top-portion {
  padding: 0px 0px;
}
.page-dashboard .top-portion .steps-tab .MuiTabs-flexContainer {
  justify-content: space-evenly;
}
.page-dashboard
  .top-portion
  .steps-tab
  .MuiTabs-flexContainer
  .MuiButtonBase-root {
  font-weight: 600;
  font-size: 16px;
  padding: 36px 16px;
  text-transform: capitalize;
}
.page-dashboard
  .top-portion
  .steps-tab
  .MuiTabs-flexContainer
  .MuiButtonBase-root.Mui-selected {
  color: var(--secondary);
}
.page-dashboard .top-portion .steps-tab .MuiTabs-indicator {
  background-color: var(--secondary);
}
.page-dashboard .top-portion .search-cover {
  margin: 0px 50px;
  padding: 0px 50px;
  border-right: 1px solid #d3d3d3;
  border-left: 1px solid #d3d3d3;
  flex: 1;
}
.page-dashboard .top-portion .search-cover .search-input {
  width: 100%;
  position: relative;
}
.page-dashboard .top-portion .search-cover .search {
  padding: 15px 50px;
  border-radius: 40px;
  width: 100%;
  border: 1px solid var(--primary);
  font-size: 18px;
  color: #666666;
}
.page-dashboard .top-portion .search-cover .search-input .search-icon {
  position: absolute;
  left: 20px;
  top: 15px;
  width: 20px;
}
.page-dashboard .top-portion .search-cover .search-input .close-icon {
  position: absolute;
  right: 20px;
  top: 15px;
  width: 20px;
}
@media screen and (min-width: 1600px) {
  .page-dashboard .top-portion .search-cover .search {
    padding: 18px 50px !important;
  }
  .page-dashboard .top-portion .search-cover .search-input .search-icon {
    top: 18px;
    width: 23px;
  }
  .page-dashboard .top-portion .search-cover .search-input .close-icon {
    top: 18px;
    width: 23px;
  }
}
.page-dashboard .top-portion .filter-icon {
  font-size: 30px;
}
.page-dashboard .dashboard-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.page-dashboard .radio-tabs {
  flex-direction: row !important;
  width: max-content;
}
.page-dashboard .lease-sale-tab {
  margin: 16px 0px 16px 30px;
  padding-right: 30px;
  border-right: 1px solid #d3d3d3;
}
.page-dashboard .radio-tabs label {
  width: 200px;
  padding: 16px 20px;
  display: inline-block;
  background: #e7f6ff;
  margin: 0px;
  color: var(--primary);
  text-align: center;
}
.page-dashboard .radio-tabs label:first-child {
  border-radius: 40px 0px 0px 40px;
}
.page-dashboard .radio-tabs label:last-child {
  border-radius: 0px 40px 40px 0px;
}
.page-dashboard .radio-tabs label.active {
  background-color: var(--primary);
  color: var(--white);
}
.page-dashboard .radio-tabs.small label {
  padding: 6px 20px;
  font-size: 16px;
  line-height: 20px;
  width: max-content;
}
.page-dashboard .radio-tabs label .MuiTypography-root {
  font-weight: bold !important;
  font-size: 18px;
}
.page-dashboard .radio-tabs.small label .MuiTypography-root {
  font-weight: 500 !important;
  font-size: 16px;
}
.page-dashboard .radio-tabs label .MuiRadio-root {
  width: 0px;
  padding: 0px;
}
.page-dashboard .radio-tabs label .MuiRadio-root span {
  display: none;
}

.page-dashboard .dashboard-header .steps-tab {
  padding: 0px 20px;
  flex: 1;
}
.page-dashboard .dashboard-header .steps-tab .MuiFormGroup-root {
  justify-content: space-around;
}
.page-dashboard
  .dashboard-header
  .steps-tab
  .MuiFormGroup-root
  .MuiTypography-root {
  font-size: 16px;
}
.page-dashboard .dashboard-header .toggle-cover {
  padding: 11px 30px 11px 0px;
}
.page-dashboard .dashboard-header .toggle-cover .MuiTypography-root {
  color: var(--primary);
  font-weight: 600;
}
.page-dashboard .comp-card {
  border-radius: 10px;
  overflow: hidden;
}
.page-dashboard .comp-card .comp-header {
  height: 234px;
  position: relative;
}
.page-dashboard .comp-card .comp-header .prop-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.page-dashboard .comp-card .comp-header .icons-cover {
  position: absolute;
  top: 20px;
  right: 20px;
}
.page-dashboard .comp-card .comp-header .image-bottom-content {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  background-image: linear-gradient(
    to bottom,
    rgba(148, 140, 140, 0),
    rgba(0, 0, 0, 0.75)
  );
}
.page-dashboard
  .comp-card
  .comp-header
  .image-bottom-content
  .brokers-list
  .broker:not(:first-child) {
  border-left: 1px solid #d3d3d3;
  padding-left: 25px;
  margin-left: 25px;
}
.page-dashboard
  .comp-card
  .comp-header
  .image-bottom-content
  .brokers-list
  .broker
  img {
  width: 38px;
  height: 38px;
  border: 2px solid var(--white);
  border-radius: 50%;
  margin-right: 15px;
}
.page-dashboard
  .comp-card
  .comp-header
  .image-bottom-content
  .brokers-list
  .broker
  h2 {
  color: var(--white);
  font-weight: bold;
  letter-spacing: 0.8px;
}
.page-dashboard .comp-card .comp-body {
  padding: 20px;
}

.dashboard-body.infinite-scroller {
  overflow-y: scroll;
  max-height: calc(100vh - 283px);
  width: calc(100% + 32px);
  padding-right: 10px;
  overflow-x: hidden;
}
.page-dashboard .top-portion .applied {
  position: relative;
}

.page-dashboard .top-portion .applied::after {
  content: "";
  position: absolute;
  right: -4px;
  top: -7px;
  width: 12px;
  height: 12px;
  background-color: #e56c1f;
  border-radius: 50%;
}

@media screen and (min-width: 1600px) {
  .page-dashboard
    .top-portion
    .steps-tab
    .MuiTabs-flexContainer
    .MuiButtonBase-root {
    font-size: 20px;
    padding: 38px 16px;
  }
  .page-dashboard .radio-tabs label .MuiTypography-root {
    font-size: 22px;
  }
  .page-dashboard
    .dashboard-header
    .steps-tab
    .MuiFormGroup-root
    .MuiTypography-root {
    font-size: 22px;
  }
  .page-dashboard .dashboard-header .toggle-cover .MuiTypography-root {
    font-size: 22px;
  }
  .dashboard-body.infinite-scroller {
    max-height: calc(100vh - 312px);
  }
  .page-dashboard .top-portion .filter-icon {
    font-size: 35px;
  }
  .page-dashboard .comp-card .comp-header {
    height: 300px;
  }
}
@media screen and (min-width: 1500px) {
  .page-dashboard .comp-card .comp-header {
    height: 270px;
  }
}
@media screen and (max-width: 1200px) {
  .page-dashboard .comp-card .comp-header {
    height: 200px;
  }
  .page-dashboard .dashboard-header .toggle-cover {
    padding: 4px 20px 4px 5px;
  }
  .page-dashboard .radio-tabs label {
    width: 100px;
    padding: 10px 15px;
  }
  .page-dashboard
    .dashboard-header
    .steps-tab
    .MuiTabs-flexContainer
    .MuiButtonBase-root {
    padding: 30px 10px;
  }
}
@media screen and (max-width: 991px) {
  .page-dashboard .top-portion .space-between {
    flex-wrap: wrap;
    padding-top: 20px;
  }
  .page-dashboard .top-portion .space-between .steps-tab {
    width: 100%;
    order: 1;
  }
  .page-dashboard .top-portion .search-cover {
    margin-left: 0px;
    padding-left: 0px;
    border-left: none;
  }
  .page-dashboard .dashboard-header {
    padding: 25px 25px 0px 25px;
    flex-wrap: wrap;
  }
  .page-dashboard .lease-sale-tab {
    width: 55%;
    order: 0;
    margin: 0px;
    border: none;
  }
  .page-dashboard .dashboard-header .steps-tab {
    width: 100%;
    order: 2;
    padding: 20px 0px;
  }
  .page-dashboard .dashboard-header .toggle-cover {
    order: 0;
    width: 45%;
    text-align: right;
    padding: 0px;
    border: none;
  }
}

@media screen and (max-width: 768px) {
  .page-dashboard .floating-add-icon {
    width: 50px;
  }
  .page-dashboard .top-portion .search-cover {
    padding-right: 30px;
    margin-right: 30px;
  }
}
@media screen and (max-width: 576px) {
  .dashboard-body.infinite-scroller {
    width: 100% !important;
  }
  .page-dashboard .top-portion .space-between {
    padding-left: 10px;
    padding-right: 10px;
  }
  .page-dashboard .top-portion .search-cover {
    padding-right: 0px;
    margin-right: 20px;
    border-right: none;
  }
  .page-dashboard .top-portion .search-cover .search {
    font-size: 12px;
    padding: 6px 25px !important;
  }
  .page-dashboard .top-portion .search-cover .search-input .search-icon {
    top: 9px;
    width: 10px;
    left: 10px;
  }
  .page-dashboard .top-portion .search-cover .search-input .close-icon {
    top: 9px;
    width: 10px;
    right: 10px;
  }
  .page-dashboard
    .top-portion
    .steps-tab
    .MuiTabs-flexContainer
    .MuiButtonBase-root {
    padding: 20px 12px 15px;
    font-size: 14px;
  }
  .page-dashboard .dashboard-header {
    padding: 20px 15px 0px 15px;
  }
  .page-dashboard .radio-tabs label {
    width: 80px;
    padding: 7px 15px;
  }
  .page-dashboard .radio-tabs label .MuiTypography-root {
    font-size: 14px;
  }
  .page-dashboard
    .dashboard-header
    .steps-tab
    .MuiTabs-flexContainer
    .MuiButtonBase-root {
    padding: 20px 12px 15px;
    font-size: 14px;
  }
  .page-dashboard .radio-tabs.small label {
    padding: 2px 6px;
    line-height: 12px;
  }
  .page-dashboard .radio-tabs.small label .MuiTypography-root {
    font-size: 10px;
  }
  .page-dashboard .dashboard-header .steps-tab {
    padding: 10px 0px;
  }
  .page-dashboard .steps-tab .MuiFormControlLabel-root {
    margin: 0px;
  }
  .page-dashboard
    .dashboard-header
    .steps-tab
    .MuiFormGroup-root
    .MuiRadio-root {
    padding: 5px;
  }
  .page-dashboard
    .dashboard-header
    .steps-tab
    .MuiFormGroup-root
    .MuiTypography-root {
    font-size: 14px;
  }
  .page-dashboard .floating-add-icon {
    width: 40px;
  }
  .page-dashboard .comp-card .comp-header .icons-cover {
    top: 15px;
    right: 15px;
    z-index: 99;
  }
  .page-dashboard .comp-card .comp-header .icons-cover img {
    width: 20px;
  }
  .page-dashboard .comp-card .comp-header .icons-cover img:first-child {
    padding-right: 10px;
    margin-right: 10px;
    width: 30px;
  }
  .page-dashboard .comp-card .comp-header .image-bottom-content {
    padding: 15px;
  }
  .page-dashboard .comp-card .comp-header .image-bottom-content img.mb-10 {
    width: 20px;
  }
  .page-dashboard .comp-card .comp-header .image-bottom-content .badge-img {
    width: 15px;
  }
  .page-dashboard
    .comp-card
    .comp-header
    .image-bottom-content
    .brokers-list
    .broker
    img {
    width: 18px;
    height: 18px;
    border-width: 1px;
    margin-right: 6px;
  }
  .page-dashboard
    .comp-card
    .comp-header
    .image-bottom-content
    .brokers-list
    h2 {
    font-size: 10px;
  }
  .page-dashboard
    .comp-card
    .comp-header
    .image-bottom-content
    .brokers-list
    .broker:not(:first-child) {
    padding-left: 7px;
    margin-left: 7px;
  }
}
@media screen and (max-width: 450px) {
  .page-dashboard .dashboard-header {
    padding: 15px 10px 0px 10px;
  }
  .page-dashboard .radio-tabs label {
    width: 70px;
    padding: 4px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .page-dashboard .radio-tabs label .MuiTypography-root {
    font-size: 12px;
  }
  .page-dashboard
    .dashboard-header
    .steps-tab
    .MuiFormGroup-root
    .MuiTypography-root {
    font-size: 12px;
  }
  .page-dashboard .floating-add-icon {
    width: 30px;
  }
}
