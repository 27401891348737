.page-myaccount .container {
    background-color: white;
    padding: 30px;
}
.page-myaccount .container .first-row{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 50px;
    border-bottom: 2px solid #ebebeb;
}
.page-myaccount .container .first-row .profile-cover{
    width: 110px;
    height: 110px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 50px;
}
.page-myaccount .container .first-row .profile-cover img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.page-myaccount .container .first-row .border-right{
    padding-right: 100px;
    border-right: 1px solid #ebebeb;
    margin-right: 80px;
}
.page-myaccount .container .space-between{
    padding: 40px 0px;
    border-bottom: 2px solid #ebebeb;
    font-size: 20px;
}
@media screen and (max-width: 767px){
    .page-myaccount .container .first-row{
        padding-bottom: 25px;
    }
    .page-myaccount .container .first-row .justify-start{
        flex-direction: column;
        align-items: flex-start;
    }
    .page-myaccount .container .first-row .justify-start .justify-start{
        flex-direction: row;
    }
    .page-myaccount .container .first-row .profile-cover{
        width: 80px;
        height: 80px;
        margin: 0px 0px 20px 0px;
    }
    .page-myaccount .container .first-row .border-right{
        padding-right: 20px;
        margin-right: 20px;
        /* border-right: none; */
        /* border-bottom: 1px solid #ebebeb; */
    }
    .page-myaccount .container .space-between{
        padding: 10px 0px 25px;
    }
}
.page-myaccount .container .space-between .icon-cover{
    min-width: 35px;
}
.page-myaccount .container .space-between .icon-cover img{
    display: table;
}
.page-myaccount .container .space-between .MuiSvgIcon-root{
    color: var(--secondary);
    cursor: pointer;
}